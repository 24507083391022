
import { defineComponent, ref } from 'vue'
import { Toast } from 'vant'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup: (props, { emit }) => {
    const mobile = ref('')
    const code = ref('')
    const counting = ref(false)
    const router = useRouter()
    return {
      mobile,
      code,
      counting,
      onSubmit: (values: any) => {
        emit('verify', values)
      },
      sendCode: () => {
        if (!/1[3-9][0-9]{9}/.test(mobile.value)) {
          Toast.fail('请输入正确的手机号！')
          return
        }
        emit('send', mobile.value, () => {
          counting.value = true
        })
      },
      countFinish: () => {
        counting.value = false
      },
      toagreement: () => {
        router.push('/agreement')
      }
    }
  }
})
